import React from "react";
import { Link } from "react-router-dom";
import "./cv.scss";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

export const CV = (): JSX.Element => {

    const pdfUrl = `${process.env.PUBLIC_URL}/CV_2.pdf`;

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/CV_2.pdf`;
        link.download = 'CV_2.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return (
        <div className="cv">

            <h1 className="cv__title"> My CV</h1>
            <button className="home__head__left__btn" onClick={handleDownload}>Download CV</button>
            
            <div className="cv__pdf" >
                <Worker  workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}>
                   <Viewer fileUrl={pdfUrl} />
              </Worker>
            </div>
        </div>
    );
};