import {CerModel} from "./Cer.model";

export const Cer : CerModel = [
    {
        certification: '/assets/certifications/LLMs Mastery.jpg',
        _id: '1',
    },
    {
        certification: '/assets/certifications/QuantumCom.jpg',
        _id: '2',
    },
    {
        certification: '/assets/certifications/LaTex.jpg',
        _id: '3',
    },
    {
        certification: '/assets/certifications/ITCBlockchain.jpeg',
        _id: '4',
    },
    {
        certification: '/assets/certifications/AlgoTradeMLAWS.jpg',
        _id: '5',
    },
    {
        certification: '/assets/certifications/AlgoTradeMQL5.jpg',
        _id: '6',
    },
    {
        certification: '/assets/certifications/IBMIEssential.png',
        _id: '7',
    },
    {
        certification: '/assets/certifications/IBMIFinance.jpg',
        _id: '8',
    },
    {
        certification: '/assets/certifications/IBMI_EaIB.png',
        _id: '9',
    },
    {
        certification: '/assets/certifications/IBMI_SaO.png',
        _id: '10',
    },
    {
        certification: '/assets/certifications/Web Design.jpg',
        _id: '11',
    },
    {
        certification: '/assets/certifications/SEO.jpg',
        _id: '12',
    },
    {
        certification: '/assets/certifications/ReadingListening.jpeg',
        _id: '13',
    },
    {
        certification: '/assets/certifications/SpeakingWriting.jpeg',
        _id: '14',
    },

];