import {AppModel} from "./Apps.model";

export const apps : AppModel = [
    {
        name: 'Electricity Demand Prediction with RNNs',
        info: 'The data contains 2,075,259 measurements gathered in a house located in Sceaux (7 km from Paris, France) between December 2006 and November 2010 (47 months). The measurements are given at a minute resolution. An RNN with LSTM layers and a dense layer with ReLU activation will predict the next hours Global_active_power. The model uses a window size of 30 data points with features including Global_active_power, Global_reactive_power, Voltage, Global_intensity, Sub_metering_1, Sub_metering_2, and Sub_metering_3.',
        icon: '/assets/projects/ElectrictyRNNs.png',
        _id: '1',
    },
    {
        name: 'AI based Algorithmic Trading',
        info: 'This project aims to create pairs of instruments and machine learning models in the first layer of the training phase. These pairs are evaluated using various metrics to generate features for each pair, forming the projects short-term memory. In the second layer, a voting classifier model selects potential pairs for real-time market operations based on their  historical  performance,  which  is  stored  in  a  database  from  the  beginning  of  the projects operation. Thus, the second layer is considered the long-term memory of the project.',
        icon: '/assets/projects/AlgoTradeDiagram.png',
        _id: '2',
    },
    {
        name: 'House Price Prediction with CNNs',
        info: 'This project is an end-to-end machine learning solution using a convolutional neural network for the house price prediction problem. The dataset contains 4 images per house and the corresponding price of each house. A dense layer with dropout for regularization is included, and the model is compiled with MSE and MAE metrics using the Adam optimizer.',
        icon: '/assets/projects/HouseCNN.png',
        _id: '3',
    },
    {
        name: 'Twitter Data Analysis',
        info: 'Bitcoin has always been affected by the sentiments of Twitter. Like Twitter, Bitcoin depends on community movements. Using Twitter data related to Bitcoin with the Twitter API and KNIME Analytics, this project demonstrates the correlation between market direction and Twitter volume, the language of tweets, and the dominant sentiment views (negative, positive, or neutral). The chart shows the significant interest of the Turkish community in Bitcoin. As a result, could it be said that the recent economic problems in Turkey are somewhat related to this issue? Additionally, other results demonstrate a negative correlation between market direction and the volume of tweets, indicating that people panic and start tweeting when the price goes down.',
        icon: '/assets/projects/TwitterBitcoinLanguage.png',
        _id: '4',
    },
    {
        name: 'Predicting Hourse Surgery Risk with RFC',
        info: 'This project involves predicting horse surgery risk using a Random Forest Classifier. A pipeline is used to streamline the process, and hyperparameters are tuned using a randomized search with 3-fold stratified cross-validation. A confusion matrix scoring function is applied to evaluate the models performance.',
        icon: '/assets/projects/confusionMatrix.png',
        _id: '5',
    },
    {
        name: 'Customer Segmentation with K-means',
        info: 'This project involves using Recency, Frequency, and Monetary (RFM) parameters in the K-Means algorithm for customer segmentation. The segmentation is adjusted to categorize customers into four groups: new customers, loyal customers, potential at-risk customers, and high spenders.',
        icon: '/assets/projects/Segmentation.png',
        _id: '6',
    },
    {
        name: 'Sales Prediction with LSTM',
        info: 'This project involves using an LSTM model for time series analysis of sales prediction. This prediction can help with stock management and optimization, enabling better preparation for future demand.',
        icon: '/assets/projects/SalesPrediction.png',
        _id: '7',
    },
    {
        name: 'Churn Classification with MLP',
        info: 'In this project, a Multi-Layer Perceptron (MLP) model was developed for the churn classification problem. The preprocessing steps included feature encoding, scaling, and imputation. The MLP model was prepared with hidden layers, dropout layers, and a sigmoid activation function. L1 regularization was applied to prevent overfitting.',
        icon: '/assets/projects/ChurnClassification.png',
        _id: '8',
    },
    {
        name: 'Creating Images with Dice',
        info: 'Dice Art is a script that takes an image as input and converts it to a grayscale image to get similar RGB values. It then groups nearby pixels and converts them into dice, matching the mean RGB value of each group with a corresponding dice number. In the end, the image is recreated using a number of dices.',
        icon: '/assets/projects/DiceArt.png',
        _id: '9',
    },
    {
        name: 'Location Detection of Users by IP',
        info: 'In this code, users IP addresses are converted to latitude and longitude using the IPStack API. The resulting coordinates are then used to display the users locations with the help of the Folium library.',
        icon: '/assets/projects/IPGeospatial.png',
        _id: '10',
    },
    {
        name: 'Stocks Corrolation Heat Map',
        info: 'This is one of many visualizations of the stock market that demonstrates the correlation of instruments, helping us to invest more diversely and detect arbitrage opportunities.',
        icon: '/assets/projects/StocksHeatmap.png',
        _id: '11',
    },
    {
        name: 'Financial Projection of Seed Round',
        info: 'This is a financial projection for the MedusaTrade startup, showing that expenses of $200,000 over 18 months as seed round funding can achieve 4,000 users and generate a revenue of $25,000 to $30,000 monthly.',
        icon: '/assets/projects/FinancialProjection.png',
        _id: '12',
    },
    {
        name: 'Prompt Engineering with Flask and OpenAI',
        info: 'This Prompt Engineering project leverages Flask and the OpenAI API to create a dynamic color palette generator. The application allows users to input a text prompt, which is then processed by OpenAIs language model to generate a list of colors that correspond to the descriptive elements of the prompt. These colors are then displayed in an HTML interface, complete with their corresponding CSS color codes. The project seamlessly integrates AI-driven creativity with web development, offering users an interactive and visually appealing way to explore color schemes inspired by their textual input.',
        icon: '/assets/projects/colorpalette.png',
        _id: '13',
    }
];